import * as io from 'io-ts';

const languages = {
    // af: io.string,
    // ar: io.string,
    // ast: io.string,
    // az: io.string,
    // bg: io.string,
    // be: io.string,
    // bn: io.string,
    // br: io.string,
    // bs: io.string,
    // ca: io.string,
    // cs: io.string,
    // cy: io.string,
    // da: io.string,
    // de: io.string,
    // dsb: io.string,
    // el: io.string,
    en: io.string,
    // 'en-au': io.string,
    // 'en-gb': io.string,
    // eo: io.string,
    // es: io.string,
    // 'es-ar': io.string,
    // 'es-co': io.string,
    // 'es-mx': io.string,
    // 'es-ni': io.string,
    // 'es-ve': io.string,
    // et: io.string,
    // eu: io.string,
    // fa: io.string,
    // fi: io.string,
    fr: io.string,
    // fy: io.string,
    // ga: io.string,
    // gd: io.string,
    // gl: io.string,
    // he: io.string,
    // hi: io.string,
    // hr: io.string,
    // hsb: io.string,
    // hu: io.string,
    // hy: io.string,
    // ia: io.string,
    // id: io.string,
    // io: io.string,
    // is: io.string,
    // it: io.string,
    // ja: io.string,
    // ka: io.string,
    // kab: io.string,
    // kk: io.string,
    // km: io.string,
    // kn: io.string,
    // ko: io.string,
    // lb: io.string,
    // lt: io.string,
    // lv: io.string,
    // mk: io.string,
    // ml: io.string,
    // mn: io.string,
    // mr: io.string,
    // my: io.string,
    // nb: io.string,
    // ne: io.string,
    nl: io.string,
    // nn: io.string,
    // os: io.string,
    // pa: io.string,
    // pl: io.string,
    // pt: io.string,
    // 'pt-br': io.string,
    // ro: io.string,
    // ru: io.string,
    // sk: io.string,
    // sl: io.string,
    // sq: io.string,
    // sr: io.string,
    // 'sr-latn': io.string,
    // sv: io.string,
    // sw: io.string,
    // ta: io.string,
    // te: io.string,
    // th: io.string,
    // tr: io.string,
    // tt: io.string,
    // udm: io.string,
    // uk: io.string,
    // ur: io.string,
    // vi: io.string,
    // 'zh-hans': io.string,
    // 'zh-hant': io.string,
};

// tslint:disable-next-line: variable-name
export const MessageRecordLangIO = io.keyof(languages);

// tslint:disable-next-line: variable-name
export const MessageRecordIO = io.intersection([
    io.partial(languages),
    io.partial({
        parameters: io.dictionary(io.string, io.any),
    }),
]);

// tslint:disable-next-line: variable-name
export const EditedRecordIO = io.interface(
    {
        id: io.number,
        key: io.string,
        record: MessageRecordIO,
    },
    'EditedRecordIO'
);

// tslint:disable-next-line: variable-name
export const EditedRecordArrayIO = io.array(EditedRecordIO);

export type MessageRecord = io.TypeOf<typeof MessageRecordIO>;
export type MessageRecordLang = io.TypeOf<typeof MessageRecordLangIO>;
export type EditedRecord = io.TypeOf<typeof EditedRecordIO>;

export const makeRecord = (fr = '', nl = '', en = ''): MessageRecord => ({
    fr,
    nl,
    en,
});

export const weakIsMessageRecord = (o: Object): o is MessageRecord => {
    if (
        o.hasOwnProperty('en') ||
        o.hasOwnProperty('fr') ||
        o.hasOwnProperty('nl')
    ) {
        return true;
    }
    return false;
};
