/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import { fromNullable, Option, some, none } from 'fp-ts/lib/Option';
import OpenLayersMap from 'ol/Map';
import View from 'ol/View';
import Feature from 'ol/Feature';
import Collection from 'ol/Collection';
import { Extent } from 'ol/extent';
import { defaults as defaultInteractions } from 'ol/interaction';
import SourceTile from 'ol/source/Tile';
import SourceTileWMS from 'ol/source/TileWMS';
import SourceVector from 'ol/source/Vector';
import LayerGroup from 'ol/layer/Group';
import LayerTile from 'ol/layer/Tile';
import LayerVector from 'ol/layer/Vector';
import { get as projGet } from 'ol/proj';

import { SyntheticLayerInfo } from '../app';
import {
    translateMapBaseLayer,
    hashMapBaseLayer,
    tryString,
    arrayEquals,
} from '../util';
import {
    DirectGeometryObject,
    Feature as GeoFeature,
    FeatureCollection,
    getMessageRecord,
    IMapBaseLayer,
    MessageRecord,
    Position as GeoPosition,
    ILayerInfo,
} from '../source';
// import { fromRecord } from '../locale';

import {
    ExtractOptions,
    FeaturePathGetter,
    FetchData,
    formatGeoJSON,
    IMapOptions,
    InteractionGetter,
    MarkOptions,
    MeasureOptions,
    PositionOptions,
    PrintOptions,
    SelectOptions,
    TrackerOptions,
    SingleClickOptions,
    EditOptions,
    IViewEvent,
    SingleSelectOptions,
    Coord2D,
    tryCoord2D,
    EPSG31370,
    HighlightOptions,
} from '.';
import { StyleFn, lineStyle, pointStyle, polygonStyle } from './style';
import { scaleLine, loadingMon } from './controls';
import { select, highlight, enter } from './actions';
import {
    measure,
    track,
    extract,
    mark,
    print,
    position,
    singleclick,
    edit,
} from './tools';

import { scopeOption } from '../lib';
import { FeatureLike } from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import { index } from 'fp-ts/lib/Array';

const logger = debug('sdi:map');

type CSMap = ReturnType<typeof create>;

const { addMap, getMap } = (function () {
    const maps: { [k: string]: CSMap } = {};

    const addMap = (name: string, m: CSMap) => {
        maps[name] = m;
        return m;
    };
    const getMap = (name: string) => fromNullable(maps[name]);

    return { addMap, getMap };
})();

const withMap =
    <ARGS extends unknown[], R>(f: (m: CSMap, ...args: ARGS) => R) =>
    (mapName: string, ...args: ARGS) =>
        getMap(mapName).map(m => f(m, ...args));

const withMapOption =
    <ARGS extends unknown[], R>(f: (m: CSMap, ...args: ARGS) => Option<R>) =>
    (mapName: string, ...args: ARGS) =>
        getMap(mapName).chain(m => f(m, ...args));

export const checkMap = withMap(() => void 0);

const removeLayerWithMap = (m: CSMap, lid: string) => {
    logger(`===== removeLayer ${lid} ====`);
    const layersArray = m.mainLayerGroup.getLayers().getArray();
    const toRemove = layersArray.find(l => l.get('id') === lid);
    if (toRemove !== undefined) {
        m.mainLayerGroup.getLayers().remove(toRemove);
    } else {
        logger(`layer(${lid}) not in collection`);
    }
};

export const removeLayer = withMap(removeLayerWithMap);

const removeLayerAllWithMap = (m: CSMap) => {
    logger('===== remove All Layers ====');
    m.mainLayerGroup.getLayers().clear();
};

export const removeLayerAll = withMap(removeLayerAllWithMap);

const getStyleFn =
    (layerInfo: () => Option<SyntheticLayerInfo>, oView: Option<View>) =>
    (a: FeatureLike, b: number) =>
        layerInfo().fold([], ({ info }) => {
            const zoomLevel = oView
                .chain(v => fromNullable(v.getZoomForResolution(b)))
                .getOrElse(b);

            switch (info.style.kind) {
                case 'polygon-continuous':
                case 'polygon-discrete':
                case 'polygon-simple':
                    return polygonStyle(info.style)(a, zoomLevel);
                case 'point-discrete':
                case 'point-simple':
                case 'point-continuous':
                    return pointStyle(info.style)(a, zoomLevel);
                case 'line-simple':
                case 'line-discrete':
                case 'line-continuous':
                    return lineStyle(info.style)(a, zoomLevel);
            }
        });

const addLayerWithMap = (
    m: CSMap,
    layerInfo: () => Option<SyntheticLayerInfo>,
    fetchData: FetchData,
    retryCount = 0
) => {
    const infoOption = layerInfo();
    if (infoOption.isSome()) {
        infoOption.map(({ info, metadata }) => {
            logger(`===== addLayer ${info.id} ====`);
            const layers = m.mainLayerGroup.getLayers();
            const alayers = layers.getArray();
            if (alayers.find(l => l.get('id') === info.id)) {
                logger('addLayer.abort');
                return;
            }
            const view: View[] = [];
            m.andThen(olmap => {
                view.push(olmap.getView());
            });
            const styleFn: StyleFn = getStyleFn(layerInfo, index(0, view));

            const vs = new SourceVector();
            const vl = new LayerVector({
                // renderMode: 'image', // IMPORTANT - but has disappeared from OL6
                source: vs,
                style: styleFn,
                maxZoom: info.maxZoom ?? 30,
                minZoom: info.minZoom ?? 0,
            });
            vs.set('id', info.id);
            vl.set('id', info.id);
            vl.setVisible(info.visible);
            layers.push(vl);
            if (metadata) {
                const title = getMessageRecord(metadata.resourceTitle);
                m.loadingMonitor.add(title);
                m.getLayerData(fetchData, vs, vl, some(title));
            } else {
                m.getLayerData(fetchData, vs, vl, none);
            }
            m.update();
        });
    } else if (retryCount < 120) {
        setTimeout(() => {
            addLayerWithMap(m, layerInfo, fetchData, retryCount + 1);
        }, retryCount * retryCount * 250);
    }
    // return null;
};

export const addLayer = withMap(addLayerWithMap);

const addFeaturesToLayerWithMap = (
    m: CSMap,
    info: ILayerInfo,
    features: GeoFeature[]
) => {
    logger('addFeaturesToLayer', features.length);
    const layers = m.mainLayerGroup.getLayers();
    const alayers = layers.getArray();
    const layer = alayers.find(l => l.get('id') === info.id);
    if (layer) {
        const source = layer.get('source');
        m.addFeatures(source, features);
    }
};

const getFeatureWithMap = (
    m: CSMap,
    layerId: string,
    featureId: number | string
) => m.getFeature(layerId, featureId);

export const getFeature = withMapOption(getFeatureWithMap);

// const addFeaturesToLayerWithMap =
//     (m: CSMap, layerInfo: () => Option<SyntheticLayerInfo>, fetchData: FetchData) => {
//         layerInfo().fold(null, ({ info, metadata }) => {
//             logger(`===== addFeaturesToLayer ${info.id} ====`);

//             const layers = m.mainLayerGroup.getLayers();
//             const alayers = layers.getArray();
//             const layer = alayers.find(l => l.get('id') === info.id);
//             if (layer) {
//                 const source = layer.get('source');
//                 const vectorLayer = <layer.Vector>layer;
//                 vectorLayer.setVisible(info.visible);

//                 if (metadata) {
//                     const title = getMessageRecord(metadata.resourceTitle);

//                     m.loadingMonitor.add(title);

//                     m.getLayerData(fetchData, source, vectorLayer, some(title));
//                 }
//                 else {
//                     m.getLayerData(fetchData, source, vectorLayer, none);
//                 }
//             }
//             else {
//                 logger(`addFeaturesToLayer.abort`);
//                 return;
//             }
//         });
//     };

export const addFeaturesToLayer = withMap(addFeaturesToLayerWithMap);

type FeatureWindow = [number, number];
export type TileLayer = LayerTile<SourceTile>;
export type VectorLayer = LayerVector<SourceVector<Geometry>>;

export const create = (mapName: string, options: IMapOptions) => {
    const baseLayerCollection = new Collection<TileLayer>();
    const baseLayerGroup = new LayerGroup({
        layers: baseLayerCollection,
    });
    baseLayerGroup.setZIndex(0);

    const mainLayerCollection = new Collection<VectorLayer>();
    const mainLayerGroup = new LayerGroup({
        layers: mainLayerCollection,
    });
    mainLayerGroup.setZIndex(10);
    mainLayerCollection.on('remove', (e: unknown) => {
        logger('mainLayerCollection::remove', e);
    });

    const toolsLayerCollection = new Collection<VectorLayer>();
    const toolsLayerGroup = new LayerGroup({
        layers: toolsLayerCollection,
    });
    toolsLayerGroup.setZIndex(100000000);

    const isTracking = false;
    const isMeasuring = false;

    const isWorking = () => {
        return isTracking || isMeasuring;
    };

    const loadingMonitor = loadingMon();

    const featureBatchInterval = 50;

    // const loadLayerData =
    //     (vs: source.Vector, fc: FeatureCollection, featureBatchSize = 1000) => {
    //         logger(`loadLayerData ${featureBatchSize} ${fc.features.length}`);
    //         const ts = performance.now();
    //         const lid = vs.get('id');
    //         const featuresRef = fc.features;
    //         const featuresSlice = featuresRef.slice(0, featureBatchSize);
    //         const data: FeatureCollection = Object.assign(
    //             {}, fc, { features: featuresSlice });
    //         const features = formatGeoJSON.readFeatures(data);
    //         vs.addFeatures(features);
    //         vs.forEachFeature((f) => {
    //             f.set('lid', lid, true);
    //             // if (!f.getId()) {
    //             //     f.setId(f.getProperties()['__app_id__']);
    //             // }
    //         });
    //         const timed = performance.now() - ts;
    //         const newBatchSize = timed > 16 ? featureBatchSize - 100 : featureBatchSize + 100;
    //         if (featuresRef.length >= newBatchSize) {
    //             const featuresNext = featuresRef.slice(newBatchSize);
    //             const nextData: FeatureCollection = Object.assign(
    //                 {}, fc, { features: featuresNext });
    //             setTimeout(() => loadLayerData(vs, nextData, newBatchSize), featureBatchInterval);
    //         }
    //     };

    const readFeatures = (
        fc: FeatureCollection,
        start: number,
        end: number,
        lid: unknown
    ) => {
        const result: Feature<Geometry>[] = [];
        for (let i = start; i < end; i += 1) {
            const f = formatGeoJSON.readFeature(fc.features[i]);
            f.set('lid', lid);
            result.push(f);
        }
        return result;
    };

    const loadLayerData = (
        vs: SourceVector<Geometry>,
        fc: FeatureCollection,
        [offset, limit]: FeatureWindow
    ) => {
        logger(`loadLayerData ${offset} ${limit} ${fc.features.length}`);
        const lid = vs.get('id');
        const end = Math.min(fc.features.length, offset + limit);
        const ts = performance.now();
        vs.addFeatures(readFeatures(fc, offset, end, lid));
        const timed = performance.now() - ts;

        if (end < fc.features.length) {
            const newLimit = timed > 16 ? Math.max(limit - 10, 10) : limit + 32;
            setTimeout(
                () => loadLayerData(vs, fc, [end, newLimit]),
                featureBatchInterval
            );
        }
    };

    const addFeatures = (
        vs: SourceVector<Geometry>,
        features: GeoFeature[]
    ) => {
        const lid = vs.get('id');
        vs.addFeatures(
            features
                .map(f => {
                    const fo = formatGeoJSON.readFeature(f);
                    fo.set('lid', lid);
                    return fo;
                })
                .filter(f =>
                    fromNullable(f.getId())
                        .map(fid => vs.getFeatureById(fid) === null)
                        .getOrElse(false)
                )
        );
    };

    const getFeature = (layerId: string, featureId: number | string) =>
        fromNullable(
            mainLayerCollection
                .getArray()
                .find(layer => layer.get('id') === layerId)
        )
            .chain(layer =>
                fromNullable(layer.getSource()).map(source => ({
                    layer,
                    source,
                }))
            )
            .chain(({ layer, source }) =>
                fromNullable(source.getFeatureById(featureId)).map(feature => ({
                    layer,
                    feature,
                }))
            );

    const getLayerData = (
        fetchData: FetchData,
        vs: SourceVector<Geometry>,
        vl: VectorLayer,
        optTitle: Option<MessageRecord>
    ) => {
        const fetcher = (count: number) => {
            // logger(`getLayerData ${fromRecord(title)} ${count}`);

            const cleanup = () => {
                // logger(`getLayerData GiveUp on ${fromRecord(title)}`);
                optTitle.map(title => loadingMonitor.remove(title));
            };

            fetchData().fold(
                () => cleanup(),
                opt =>
                    opt.foldL(
                        () => {
                            if (count < 100) {
                                setTimeout(() => fetcher(count + 1), 1000);
                            } else {
                                cleanup();
                            }
                        },
                        data => {
                            const complete = () => {
                                loadLayerData(vs, data, [0, 300]);
                                // forceRedraw();
                            };
                            if (vl.getVisible()) {
                                complete();
                            } else {
                                vl.once('change:visible', complete);
                            }
                            optTitle.map(title => loadingMonitor.remove(title));
                        }
                    )
            );
        };

        fetcher(0);
    };

    const fromBaseLayer = (baseLayer: IMapBaseLayer) => {
        const baseLayerTranslated = translateMapBaseLayer(baseLayer);
        const l = new LayerTile({
            source: new SourceTileWMS({
                projection: projGet(baseLayerTranslated.srs) || EPSG31370,
                params: {
                    ...baseLayerTranslated.params,
                    // It breaks on some servers, esp. IGN/NGI
                    // TILED: true,
                },
                url: baseLayerTranslated.url,
                crossOrigin: 'Anonymous',
            }),
        });
        l.set('id', hashMapBaseLayer(baseLayer));
        l.setZIndex(0);
        return l;
    };

    type UpdateFn = () => void;
    interface Updatable {
        name: string;
        fn: UpdateFn;
    }

    const updateBaseLayer =
        (getBaseLayer: IMapOptions['getBaseLayer']) => () => {
            const requestedBaseLayers = getBaseLayer().slice().reverse();
            const requestedBaseLayersIds =
                requestedBaseLayers.map(hashMapBaseLayer);
            const baseLayerIds = baseLayerCollection
                .getArray()
                .map(bl => tryString(bl.get('id')).getOrElse('__none__'));
            if (arrayEquals(requestedBaseLayersIds, baseLayerIds)) {
                return;
            }
            baseLayerCollection.clear();
            requestedBaseLayers.map(baseLayer =>
                baseLayerCollection.push(fromBaseLayer(baseLayer))
            );
        };

    const updateLayers = (getMapInfo: IMapOptions['getMapInfo']) => () =>
        fromNullable(getMapInfo()).map(mapInfo => {
            const ids = mapInfo.layers.map(info => info.id);
            logger(`updateLayers ${ids}`);
            mainLayerGroup.getLayers().forEach(l => {
                if (l) {
                    const lid = <string>l.get('id');
                    if (ids.indexOf(lid) < 0) {
                        mainLayerGroup.getLayers().remove(l);
                    }
                }
            });
            mapInfo.layers.forEach((info, z) => {
                const { id, visible } = info;
                mainLayerGroup.getLayers().forEach(l => {
                    if (id === <string>l.get('id')) {
                        // logger(`Layer ${id} ${visible} ${z}`);
                        l.setVisible(visible);
                        l.setZIndex(z + 10);
                        l.setMaxZoom(info.maxZoom ?? 30);
                        l.setMinZoom(info.minZoom ?? 0);
                    }
                });
            });
        });

    const forceRedraw = () => {
        mainLayerCollection.forEach(layer => {
            layer.changed();
        });
    };

    const viewEquals =
        (z: number, r: number, oc: Option<Coord2D>) =>
        (rz: number, rr: number, orc: Option<Coord2D>) =>
            scopeOption()
                .let('c', oc)
                .let('rc', orc)
                .map(
                    ({ c, rc }) =>
                        z === rz && r === rr && c[0] === rc[0] && c[1] === rc[1]
                )
                .getOrElse(false);

    // concat :: ([a],[a]) -> [a]
    const concat = <A>(xs: A[], ys: A[]) => xs.concat(ys);

    const flatten = <T>(xs: T[][]) => xs.reduce(concat, []);

    const flattenCoords = (g: DirectGeometryObject): GeoPosition[] => {
        switch (g.type) {
            case 'Point':
                return [g.coordinates];
            case 'MultiPoint':
                return g.coordinates;
            case 'LineString':
                return g.coordinates;
            case 'MultiLineString':
                return flatten(g.coordinates);
            case 'Polygon':
                return flatten(g.coordinates);
            case 'MultiPolygon':
                return flatten(flatten(g.coordinates));
        }
    };

    // const getExtent = (feature: GeoFeature, buf = 50): Extent => {
    //     const initialExtent: Extent = [
    //         Number.MAX_VALUE,
    //         Number.MAX_VALUE,
    //         Number.MIN_VALUE,
    //         Number.MIN_VALUE,
    //     ];

    //     if (feature.geometry.type === 'Point') {
    //         const [x, y] = feature.geometry.coordinates;
    //         return [x - buf, y - buf, x + buf, y + buf];
    //     } else if (feature.geometry.type === 'MultiPoint') {
    //         return feature.geometry.coordinates.reduce<Extent>((acc, c) => {
    //             return [
    //                 Math.min(acc[0], c[0] - buf),
    //                 Math.min(acc[1], c[1] - buf),
    //                 Math.max(acc[2], c[0] + buf),
    //                 Math.max(acc[3], c[1] + buf),
    //             ];
    //         }, initialExtent);
    //     }

    //     return flattenCoords(feature.geometry).reduce<Extent>((acc, c) => {
    //         return [
    //             Math.min(acc[0], c[0]),
    //             Math.min(acc[1], c[1]),
    //             Math.max(acc[2], c[0]),
    //             Math.max(acc[3], c[1]),
    //         ];
    //     }, initialExtent);
    // };

    const getExtentFromFeatures = (
        features: GeoFeature[],
        buf = 50
    ): Extent => {
        const initialExtent: Extent = [
            Number.MAX_VALUE,
            Number.MAX_VALUE,
            Number.MIN_VALUE,
            Number.MIN_VALUE,
        ];

        if (features.length > 0) {
            const ftype = features[0].geometry.type;
            if (ftype === 'Point') {
                const extent = features
                    .map(f => f.geometry.coordinates)
                    .reduce<Extent>((acc, c) => {
                        if (
                            typeof c[0] == 'number' &&
                            typeof c[1] == 'number'
                        ) {
                            return [
                                Math.min(acc[0], c[0] - buf),
                                Math.min(acc[1], c[1] - buf),
                                Math.max(acc[2], c[0] + buf),
                                Math.max(acc[3], c[1] + buf),
                            ];
                        }
                        return acc;
                    }, initialExtent);
                // console.log(`extent: ${extent[0]}`);
                return extent;
            } else if (
                features.reduce(
                    (acc, val) =>
                        acc && val.geometry.type === 'MultiPoint'
                            ? true
                            : false,
                    true
                )
            ) {
                return features
                    .map(f => f.geometry.coordinates)
                    .flat()
                    .reduce<Extent>((acc, c) => {
                        if (
                            typeof c !== 'number' &&
                            typeof c[0] == 'number' &&
                            typeof c[1] == 'number'
                        ) {
                            return [
                                Math.min(acc[0], c[0] - buf),
                                Math.min(acc[1], c[1] - buf),
                                Math.max(acc[2], c[0] + buf),
                                Math.max(acc[3], c[1] + buf),
                            ];
                        }
                        return acc;
                    }, initialExtent);
            }
            return features
                .map(feature => flattenCoords(feature.geometry))
                .flat()
                .reduce<Extent>((acc, c) => {
                    return [
                        Math.min(acc[0], c[0]),
                        Math.min(acc[1], c[1]),
                        Math.max(acc[2], c[0]),
                        Math.max(acc[3], c[1]),
                    ];
                }, initialExtent);
        }
        return initialExtent;
    };

    const updateView =
        (
            map: OpenLayersMap,
            getView: IMapOptions['getView'],
            setView: IMapOptions['updateView']
        ) =>
        () => {
            const { dirty, zoom, rotation, center, feature, features, extent } =
                getView();
            const view = map.getView();
            const eq = viewEquals(zoom, rotation, tryCoord2D(center));
            const size = map.getSize();
            const mapExtent = () => view.calculateExtent(size);

            switch (dirty) {
                case 'geo/feature': {
                    const featureList = fromNullable(features).fold(
                        feature == null ? [] : [feature],
                        fs =>
                            fs.length == 0 && feature != null ? [feature] : fs
                    );

                    const extent = getExtentFromFeatures(featureList);
                    view.fit(extent, {
                        size,
                        callback: () =>
                            setView({
                                dirty: 'none',
                                extent: mapExtent(),
                                zoom: view.getZoom(),
                                center: view.getCenter(),
                                rotation: view.getRotation(),
                            }),
                    });

                    // fromNullable(feature).map(feature => {
                    //     const extent = getExtent(feature);
                    //     view.fit(extent, {
                    //         size,
                    //         callback: () =>
                    //             setView({
                    //                 dirty: 'none',
                    //                 extent: mapExtent(),
                    //                 zoom: view.getZoom(),
                    //                 center: view.getCenter(),
                    //                 rotation: view.getRotation(),
                    //             }),
                    //     });
                    // });
                    break;
                }
                case 'geo/extent': {
                    fromNullable(extent).map(extent =>
                        view.fit(extent, {
                            size: map.getSize(),
                            callback: () =>
                                setView({
                                    dirty: 'none',
                                    extent: mapExtent(),
                                    zoom: view.getZoom(),
                                    center: view.getCenter(),
                                    rotation: view.getRotation(),
                                }),
                        })
                    );
                    break;
                }
                case 'geo': {
                    const z = view.getZoom();
                    if (
                        z !== undefined &&
                        !eq(z, view.getRotation(), tryCoord2D(view.getCenter()))
                    ) {
                        view.animate({ zoom, rotation, center }, () =>
                            setView({
                                dirty: 'none',
                                extent: mapExtent(),
                                zoom: view.getZoom(),
                                center: view.getCenter(),
                                rotation: view.getRotation(),
                            })
                        );
                    }
                    break;
                }
                case 'style': {
                    window.setTimeout(
                        () =>
                            setView({
                                dirty: 'none',
                                extent: mapExtent(),
                                zoom: view.getZoom(),
                                center: view.getCenter(),
                                rotation: view.getRotation(),
                            }),
                        0
                    );
                    forceRedraw();
                    break;
                }
                case 'none':
                    break;
            }
        };

    const updateSize = (map: OpenLayersMap) => {
        let containerWidth = 0;
        let containerHeight = 0;

        const inner = () => {
            const container = map.getViewport();
            const rect = container.getBoundingClientRect();
            if (
                rect.width !== containerWidth ||
                rect.height !== containerHeight
            ) {
                containerHeight = rect.height;
                containerWidth = rect.width;
                map.updateSize();
                const zoom = view.getZoom();
                const center = view.getCenter();
                const rotation = view.getRotation();
                const extent = view.calculateExtent(map.getSize());
                options.updateView({
                    dirty: 'none',
                    center,
                    rotation,
                    zoom,
                    extent,
                });
            }
        };

        return () => setTimeout(inner, 1);
    };

    const view = new View({
        projection: EPSG31370,
        center: [149546, 169775],
        rotation: 0,
        zoom: 30,
    });

    const theMap = new OpenLayersMap({
        view,
        layers: [baseLayerGroup, mainLayerGroup, toolsLayerGroup],
        controls: [
            scaleLine({
                setScaleLine: options.setScaleLine,
                minWidth: 100,
            }),
        ],
        interactions: defaultInteractions({
            onFocusOnly: false,
        }),
        moveTolerance: 6,
    });

    const updatables: Updatable[] = [
        { name: 'BaseLayer', fn: updateBaseLayer(options.getBaseLayer) },
        { name: 'Layers', fn: updateLayers(options.getMapInfo) },
        {
            name: 'View',
            fn: updateView(theMap, options.getView, options.updateView),
        },
        { name: 'Size', fn: updateSize(theMap) },
    ];

    const followers: [string, (v: IViewEvent) => void][] = [];

    fromNullable(options.element).map(e => theMap.setTarget(e));

    fromNullable(options.setLoading).map(s => loadingMonitor.onUpdate(s));

    const lastKnownViewUpdate = [0, 0, 0, 0];

    const onViewChange = () => {
        if (!(isWorking() || view.getAnimating())) {
            const zoom = view.getZoom() ?? 0;
            const center = view.getCenter() ?? [0, 0];
            const rotation = view.getRotation();
            // console.log(`VIEW CHANGE CENTER: ${view.getCenter()}`);
            // quick check
            if (
                lastKnownViewUpdate[0] !== zoom ||
                lastKnownViewUpdate[1] !== center[0] ||
                lastKnownViewUpdate[2] !== center[1] ||
                lastKnownViewUpdate[3] !== rotation
            ) {
                const rotation = view.getRotation();
                const extent = view.calculateExtent(theMap.getSize());
                options.updateView({
                    dirty: 'none',
                    center,
                    rotation,
                    zoom,
                    extent,
                });
                followers.forEach(([, u]) =>
                    u({ dirty: 'geo', center, rotation, zoom, extent })
                );
                lastKnownViewUpdate[0] = zoom;
                lastKnownViewUpdate[1] = center[0];
                lastKnownViewUpdate[2] = center[1];
                lastKnownViewUpdate[3] = rotation;
            }
        }
    };

    view.on('change', onViewChange);

    const follow = (name: string, u: (v: IViewEvent) => void) => {
        if (followers.findIndex(f => f[0] === name) < 0) {
            followers.push([name, u]);
        }
    };

    const update = () => {
        const us = updatables.map(u => {
            u.fn();
            return u.name;
        });
        logger(`updated ${us.join(', ')} @ z${view.getZoom()}`);
    };

    const setTarget = (target: HTMLElement | null) => {
        const currentTarget = theMap.getTarget();
        if (target && currentTarget !== target) {
            theMap.setTarget(target);
        }
    };

    const andThen = (f: (m: OpenLayersMap) => void) => f(theMap);

    const selectable = (o: SelectOptions, g: InteractionGetter) => {
        const { init, update } = select(o, mainLayerCollection);
        init(theMap);
        updatables.push({ name: 'Select', fn: () => update(g()) });
    };

    const enterable = (o: SingleSelectOptions, g: InteractionGetter) => {
        const { init, update } = enter(o, mainLayerCollection);
        init(theMap);
        updatables.push({ name: 'Enter', fn: () => update(g()) });
    };

    const trackable = (o: TrackerOptions, g: InteractionGetter) => {
        const { init, update } = track(o);
        init(theMap, toolsLayerCollection);
        updatables.push({ name: 'Tracker', fn: () => update(g()) });
    };

    const measurable = (o: MeasureOptions, g: InteractionGetter) => {
        const { init, update } = measure(o);
        init(theMap, toolsLayerCollection);
        updatables.push({ name: 'Measure', fn: () => update(g()) });
    };

    const extractable = (o: ExtractOptions, g: InteractionGetter) => {
        const { init, update } = extract(o);
        init(theMap, mainLayerCollection);
        updatables.push({ name: 'Extract', fn: () => update(g()) });
    };

    const markable = (o: MarkOptions, g: InteractionGetter) => {
        const { init, update } = mark(o);
        init(theMap);
        updatables.push({ name: 'Mark', fn: () => update(g()) });
    };

    const highlightable = (fpg: FeaturePathGetter | HighlightOptions) => {
        const { init, update } = highlight(fpg);
        init(mainLayerCollection, toolsLayerCollection);
        updatables.push({ name: 'Highlight', fn: () => update() });
    };

    const printable = <T>(o: PrintOptions<T>, g: InteractionGetter) => {
        const { init, update } = print(o);
        init(
            theMap,
            baseLayerCollection,
            options.getMapInfo,
            mainLayerCollection
        );
        updatables.push({ name: 'Print', fn: () => update(g()) });
    };

    const positionable = (o: PositionOptions, g: InteractionGetter) => {
        const { init, update } = position(o);
        init(theMap);
        updatables.push({ name: 'Position', fn: () => update(g()) });
    };

    const clickable = (o: SingleClickOptions, g: InteractionGetter) => {
        const { init, update } = singleclick(o);
        init(theMap);
        updatables.push({ name: 'SingleClick', fn: () => update(g()) });
    };

    const editable = (o: EditOptions, g: InteractionGetter) => {
        const { init, update } = edit(o);
        init(theMap, toolsLayerCollection, mainLayerCollection);
        updatables.push({ name: 'Edit', fn: () => update(g()) });
    };

    const m = {
        setTarget,
        update,
        follow,
        selectable,
        enterable,
        trackable,
        measurable,
        extractable,
        markable,
        highlightable,
        printable,
        positionable,
        clickable,
        editable,
        // the following attributes are private-ish
        // and mostly a temp hack to get things going
        getLayerData,
        addFeatures,
        loadingMonitor,
        mainLayerGroup,
        andThen,
        getFeature,
    };
    addMap(mapName, m);
    return m;
};

logger('loaded');
