/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { ChangeEvent, KeyboardEvent } from 'react';

import {
    BUTTON,
    DIV,
    INPUT,
    NodeOrOptional,
    SPAN,
} from '../components/elements';
import { tr, Translated } from '../locale';
import { isENTER } from '../components/keycodes';
import { getLang } from '../app';

import { queryService } from '../geocoder/index';
import { ServiceRequest, ServiceResult } from '../geocoder/io';
import {
    getAllServices,
    getAllServicesCodes,
    getSelectedService,
    getServicePlaceholder,
    getServiceResponse,
    getServiceName,
    getTerm,
    getSearchCoordinates,
} from '../geocoder/queries';
import {
    clearServiceResponse,
    parseSearchCoordinates,
    selectService,
    updateSearchCoord,
    updateTerm,
} from '../geocoder/events';
import { renderSelect } from '../components/input';

// import { updateGeocoderTerm } from '../../events/legend'; // set in sdi shape?
// import queries from '../../queries/legend';  // set in sdi shape?
// import { viewEvents, putMark } from '../../events/map';
import { makeIcon } from './button';
import { Coordinate } from 'ol/coordinate';
import { IMapViewData, Interaction, viewEventsFactory } from '../map';
import { Setter } from '../shape';
import { Tooltip } from './tooltip';
import { setoidString } from 'fp-ts/lib/Setoid';
import { fromPredicate } from 'fp-ts/lib/Option';
import { IconName, nameToString } from './button/names';

type ResultAction = (
    result: ServiceResult,
    setView: Setter<IMapViewData>,
    setInteraction: Setter<Interaction>
) => NodeOrOptional;

const putMark = (
    coordinates: Coordinate,
    setInteraction: Setter<Interaction>
) =>
    setInteraction(() => ({
        label: 'mark',
        state: {
            started: false,
            endTime: Date.now() + 3000,
            coordinates,
        },
    }));

const updateAddress = (e: ChangeEvent<HTMLInputElement>) => {
    updateTerm(e.target.value);
};

const searchAddress = (service_code: string) => {
    const lang = getLang();
    const serviceRequest: ServiceRequest = {
        service: service_code,
        input: getTerm(),
        lang,
    };
    queryService(serviceRequest);
};

// const renderResultZoom = (
//     key: number,
//     result: ServiceResult,
//     setView: Setter<IMapViewData>,
//     setInteraction: Setter<Interaction>
// ) => {
//     const coords: [number, number] = [result.coord.x, result.coord.y];
//     const viewEvents = viewEventsFactory(setView);
//     return DIV(
//         {
//             className: 'result',
//             key,
//             onClick: () => {
//                 clearServiceResponse();
//                 viewEvents.updateMapView({
//                     dirty: 'geo',
//                     center: coords,
//                     zoom: 12,
//                 });
//                 putMark(coords, setInteraction);
//             },
//         },
//         BUTTON({
//             className: 'select-icon',
//             "aria-labelledby": `adress-${key}`
//         }),

//         SPAN({
//             id: `adress-${key}`,
//         },
//             result.message));
// }

// const analyseToottip: Tooltip = { text: () => tr.core('analyseAtAddress') as Translated, position: 'right' }

const zoomTo = (
    coords: Coordinate,
    setInteraction: Setter<Interaction>,
    setView: Setter<IMapViewData>
) => {
    const viewEvents = viewEventsFactory(setView);
    clearServiceResponse();
    viewEvents.updateMapView({
        dirty: 'geo',
        center: coords,
        zoom: 12,
    });
    putMark(coords, setInteraction);
};

// const actionSelect =
//     (
//         coords: Coordinate,
//         setInteraction: Setter<Interaction>,
//         setView: Setter<IMapViewData>,
//         select: (coord: Coordinate) => void

//     ) => {
//         return DIV('action-select',
//             makeIcon('select', 3, 'map-marker-alt', zoomToolTip)(() => zoomTo(coords, setInteraction, setView)),
//             makeIcon('select', 3, 'play', analyseToottip)(() => select(coords))
//         );
//     };

export const actionCenter: ResultAction = (
    result: ServiceResult,
    setView: Setter<IMapViewData>,
    setInteraction: Setter<Interaction>
) => {
    const coords: [number, number] = [result.coord.x, result.coord.y];
    const tooltip: Tooltip = {
        text: `${tr.core('zoomToAddress')}\n${result.message}` as Translated,
        position: 'bottom-right',
    };
    const button = makeIcon('select', 3, 'map-marker-alt', tooltip);
    const action = () => zoomTo(coords, setInteraction, setView);
    return button(action);
};

export const makeActionSelect =
    (actionName: string, action: (c: Coordinate) => void): ResultAction =>
    (
        result: ServiceResult,
        _setView: Setter<IMapViewData>,
        _setInteraction: Setter<Interaction>
    ) => {
        const coords: [number, number] = [result.coord.x, result.coord.y];
        const tooltip: Tooltip = {
            text: `${actionName}\n${result.message}` as Translated,
            position: 'bottom-right',
        };
        const button = makeIcon('select', 3, 'play', tooltip);
        return button(() => action(coords));
    };

const renderResult = (
    key: number,
    result: ServiceResult,
    setView: Setter<IMapViewData>,
    setInteraction: Setter<Interaction>,
    actions: ResultAction[]
) => {
    const coords: [number, number] = [result.coord.x, result.coord.y];
    if (actions.length === 1) {
        // if there is only one action, perform it when clicking on the result (not only the icon)
        // for now it's considered to be the "zoom-to" action to be performed
        return DIV(
            {
                className: 'result-select simple',
                key,
            },
            BUTTON(
                {
                    id: `geocoder-result-${key}`,
                    className: 'result-label',
                    onClick: () => zoomTo(coords, setInteraction, setView),
                },
                SPAN('label', result.message),
                SPAN('fa icon', nameToString('map-marker-alt'))
            )
        );
    } else {
        return DIV(
            {
                className: 'result-select',
                key,
            },
            SPAN(
                {
                    id: `geocoder-result-${key}`,
                    className: 'result-label',
                },
                result.message
            ),
            DIV(
                'result-actions',
                ...actions.map(action =>
                    action(result, setView, setInteraction)
                )
            )
        );
    }
};

const renderResults = (
    results: ServiceResult[],
    setView: Setter<IMapViewData>,
    setInteraction: Setter<Interaction>,
    actions: ResultAction[]
) =>
    results.map((result, key) =>
        renderResult(key, result, setView, setInteraction, actions)
    );
// results.map((result, key) =>
//     select.fold(
//         renderResultZoom(key, result, setView, setInteraction),
//         s => renderResultZoomAndSelect(key, result, setView, setInteraction, s),
//     )
// );

const btnSearch = makeIcon('search', 1, 'search', {
    position: 'right',
    text: () => tr.core('search'),
});

const renderService = (
    placeholder: string,
    serviceCode: string,
    setView: Setter<IMapViewData>,
    setInteraction: Setter<Interaction>,
    actions: ResultAction[]
) =>
    DIV(
        'search-widget',
        DIV(
            'search',
            INPUT({
                type: 'text',
                name: 'adress',
                placeholder: `${placeholder}`,
                onChange: updateAddress,
                onKeyPress: (e: KeyboardEvent<HTMLInputElement>) => {
                    if (isENTER(e)) {
                        searchAddress(serviceCode);
                    }
                },
            }),
            btnSearch(() => searchAddress(serviceCode))
        ),
        getServiceResponse().map(r =>
            r.results.length > 0
                ? DIV(
                      'search-results',
                      renderResults(r.results, setView, setInteraction, actions)
                  )
                : DIV('search-results', DIV('', tr.core('geocodeNoResults')))
        )
    );

const updateCoord = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    parseSearchCoordinates(val).map(c => updateSearchCoord(c));
};

const renderCoordHelpText = () => DIV('search-results', tr.core('searchCoord'));

const renderCoordSearch = (
    placeholder: string,
    setView: Setter<IMapViewData>,
    setInteraction: Setter<Interaction>,
    select: CoordExtraAction
) => {
    const buttonCenter = makeIcon('search', 1, 'map-marker-alt', {
        position: 'bottom',
        text: tr.core('search'),
    });
    const buttonSelect = makeIcon('select', 1, select.icon, {
        position: 'bottom',
        text: select.name as Translated,
    });

    return DIV(
        'search',
        INPUT({
            type: 'text',
            name: 'adress',
            placeholder: `${placeholder}`,
            onChange: updateCoord,
            onKeyPress: (e: KeyboardEvent<HTMLInputElement>) => {
                if (isENTER(e)) {
                    getSearchCoordinates().map(coords =>
                        zoomTo(coords, setInteraction, setView)
                    );
                }
            },
        }),
        renderCoordHelpText(),
        getSearchCoordinates()
            .map(coords =>
                DIV(
                    'coord-actions',
                    buttonCenter(
                        () => zoomTo(coords, setInteraction, setView),
                        'enable'
                    ),
                    buttonSelect(() => select.action(coords), 'enable')
                )
            )
            .getOrElse(
                DIV(
                    'coord-actions disabled',
                    buttonCenter(() => {}, 'disabled'),
                    buttonSelect(() => {}, 'disabled')
                )
            )
    );
};

const renderServiceList = renderSelect(
    `geocoder-service`,
    c => getServiceName(c),
    selectService,
    setoidString
);

const renderServiceOrList = (withCoord: boolean) => {
    const services = getAllServices(withCoord);
    return services.length === 1
        ? DIV('mono', getServiceName(services[0].code))
        : renderServiceList(
              getAllServicesCodes(withCoord),
              getSelectedService()
          );
};

const renderGeocoder = (
    setView: Setter<IMapViewData>,
    setInteraction: Setter<Interaction>,
    actions: ResultAction[]
) =>
    getSelectedService().map(service =>
        DIV(
            `search-wrapper service-${service}`,
            renderService(
                getServicePlaceholder(service),
                service,
                setView,
                setInteraction,
                actions
            )
        )
    );

const isCoordinateService = fromPredicate<string>(s => s === 'coordinate');

const renderGeocoderWithCoordinate = (
    setView: Setter<IMapViewData>,
    setInteraction: Setter<Interaction>,
    actions: ResultAction[],
    select: CoordExtraAction
) =>
    getSelectedService().map(service =>
        DIV(
            `search-wrapper service-${service}`,
            isCoordinateService(service)
                .map(s =>
                    renderCoordSearch(
                        getServicePlaceholder(s),
                        setView,
                        setInteraction,
                        select
                    )
                )
                .getOrElse(
                    renderService(
                        getServicePlaceholder(service),
                        service,
                        setView,
                        setInteraction,
                        actions
                    )
                )
        )
    );

export const render = (
    setView: Setter<IMapViewData>,
    setInteraction: Setter<Interaction>,
    actions = [actionCenter]
) =>
    DIV(
        'geocoder-wrapper',
        renderServiceOrList(false),
        renderGeocoder(setView, setInteraction, actions)
    );

export interface CoordExtraAction {
    name: string;
    icon: IconName;
    action: (c: Coordinate) => void;
}

export const geocoderWithCoords = (
    setView: Setter<IMapViewData>,
    setInteraction: Setter<Interaction>,
    actions: ResultAction[],
    select: CoordExtraAction
) =>
    DIV(
        'geocoder-wrapper',
        renderServiceOrList(true),
        renderGeocoderWithCoordinate(setView, setInteraction, actions, select)
    );

export default render;
